<template>
  <div id="app" class="bg-black text-white min-h-screen break-words">
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@200;400;600&display=swap');

* {
  font-family: 'IBM Plex Mono', monospace;
  color: #cccccc;
}
</style>
